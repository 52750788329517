import React from 'react';
import * as R from 'ramda';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose, lifecycle, withProps } from 'react-recompose';
// components
import { FormFooter2 } from '../../components/form-footer';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// hocs
import { withAsyncGetUnitIdListByEntityType } from '../../hocs/with-async-get-endpoint-hocs';
// forms
import { Fieldset2 } from '../formik/fieldset2/fieldset';
//////////////////////////////////////////////////

const getFieldSettings = (entityType: string) => [
  {
    isRequired: true,
    type: 'reactSelect',
    options: 'unitIdOptions',
    fieldName: GC.FIELD_UNIT_ID,
    inputWrapperStyles: { mb: 25, width: 270 },
    label: G.ifElse(R.equals(entityType, GC.FIELD_TRUCK), ['titles:truck', 'Truck'], ['titles:trailer', 'Trailer']),
  },
];

const enhance = compose(
  withAsyncGetUnitIdListByEntityType,
  withProps(({ asyncUnitIdList }: Object) => {
    let unitIdOptions = [];

    if (G.isNotNilAndNotEmpty(asyncUnitIdList)) {
      unitIdOptions = R.map(({ guid, unitId }: Object) => ({
        [GC.FIELD_VALUE]: guid,
        [GC.FIELD_LABEL]: unitId,
      }), asyncUnitIdList);
    }

    return { unitIdOptions };
  }),
  withFormik({
    mapPropsToValues: () => ({ [GC.FIELD_UNIT_ID]: '' }),
    validationSchema: Yup.object().shape({ [GC.FIELD_UNIT_ID]: G.yupStringRequired }),
    handleSubmit: ({ unitId }: Object, { props: { submitAction } }: Object) => submitAction(unitId),
  }),
  lifecycle({
    componentDidMount() {
      this.props.getAsyncGetUnitIdList();
    },
  }),
  pure,
);

export const SelectTruckOrTrailerForm = enhance((props: Object) => {
  const { entityType, handleSubmit, unitIdOptions } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...G.getFormikPropsToFieldset(props)}
        unitIdOptions={unitIdOptions}
        fields={getFieldSettings(entityType)}
        fieldsWrapperStyles={{ pt: 15, px: '0px', justifyContent: 'space-between' }}
      />
      <FormFooter2 />
    </form>
  );
});
