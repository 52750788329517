import React from 'react';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
// forms
import { Fieldset2 } from '../../';
//////////////////////////////////////////////////

const OPTION = 'option';

const getValidationSchema = (optionRequired: any, isMulti: boolean = false) => {
  if (G.isFalse(optionRequired)) return {};

  if (isMulti) return { [OPTION]: G.yupArrayRequired };

  return {
    [OPTION]: Yup.string()
      .nullable(true)
      .required(G.getRequiredLocaleTxt()),
  };
};

export const getFields = (options: Array, fieldWidth: number = 300, label: any = null, isMulti: boolean = false) => [
  {
    label,
    isMulti,
    options,
    fieldName: OPTION,
    type: 'reactSelect',
    inputWrapperStyles: { mb: 25, width: fieldWidth },
  },
];

export const enhance = compose(
  withFormik({
    enableReinitialize: true,
    displayName: 'SINGLE_SELECT_DROPDOWN',
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values[OPTION]),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues({ [OPTION]: null }, initialValues),
    validationSchema: ({ isMulti, optionRequired }: Object) =>
      Yup.object().shape(getValidationSchema(optionRequired, isMulti)),
  }),
  pure,
);

export const SelectDropdownForm = enhance((props: Object) => {
  const {
    isMulti,
    options,
    fieldWidth,
    fieldLabel,
    handleSubmit,
    cancelAction,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Fieldset2
        {...props}
        fieldsWrapperStyles={{ pt: 15 }}
        fields={getFields(options, fieldWidth, fieldLabel, isMulti)}
      />
      <FormFooter2 closeModal={cancelAction} />
    </form>
  );
});
