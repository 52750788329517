import React from 'react';
import * as R from 'ramda';
import { pure, compose, withState, withHandlers } from 'react-recompose';
// components
import { FormGroupTitleMultiple } from '../../components/form-group-title-multiple';
// forms
import { SelectLocation } from './select-location';
import { LocationTemplateSearch } from './template-search/components/location-template-search';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box } from '../../ui';
// components/forms
import BranchLocationForm from './branch-location-form';
import BillToLocationForm, { ContractBillToLocationForm } from './bill-to-location-form';
//////////////////////////////////////////////////

const enhance = compose(
  withState('initialValues', 'setInitialValues', ({ initialValues }: Object) => initialValues),
  withHandlers({
    handleSearchLocation: (props: Object) => () => {
      const { formType, closeModal, setInitialValues } = props;

      const searchTemplateRequest = (location: Object) => {
        let mappedLocation = G.mapSearchedLocation2(location, { useMultiEmails: true });

        if (R.equals(formType, GC.LOCATION_FORM_TYPE_BRANCH_LOCATION)) {
          mappedLocation = G.mapSearchedLocationWithContactsArr(location);
        }

        setInitialValues((prev: Object) => R.mergeRight(prev, mappedLocation));
        closeModal();
      };
      const modalContent = (
        <LocationTemplateSearch
          {...G.getLocationTemplateSearchRequestProps(props)}
          closeModal={closeModal}
          searchTemplateRequest={searchTemplateRequest}
        />
      );
      const modal = G.createCommonModalOptions(modalContent);
      props.openModal(modal);
    },
    handleSelectLocation: (props: Object) => () => {
      const searchTemplateRequest = (location: Object) => {
        const needValues = R.pick(GC.GROUPED_FIELDS.EVENT_LOCATION_ARR, location);
        props.setInitialValues((prev: Object) => R.mergeRight(prev, needValues));
        props.closeModal();
      };
      const modalContent = (
        <SelectLocation
          closeModal={props.closeModal}
          initialValues={props.initialValues}
          searchTemplateRequest={searchTemplateRequest}
        />
      );
      const modal = G.createCommonModalOptions(modalContent);
      props.openModal(modal);
    },
  }),
  pure,
);

const renderTitle = ({ eventType, mode }: Object) => (
  `${G.toTitleCase(mode)} ${G.toTitleCase(eventType)}`
);

const FormComponent = (props: Object) => {
  if (R.propEq(GC.LOCATION_FORM_TYPE_BILL_TO, 'formType', props)) {
    return <BillToLocationForm {...props} />;
  }

  if (R.propEq(GC.LOCATION_FORM_TYPE_BRANCH_LOCATION, 'formType', props)) {
    return <BranchLocationForm {...props} />;
  }

  if (R.propEq(GC.LOCATION_FORM_TYPE_CONTRACT_BILL_TO_LOCATION, 'formType', props)) {
    return <ContractBillToLocationForm {...props} />;
  }
};

export const LocationFormWithSearch = enhance((props: Object) => (
  <Box width={655}>
    <FormGroupTitleMultiple
      mb='0'
      showArrowToggle={false}
      title={renderTitle(props)}
    >
      <Box ml={10} pt='4px' cursor='pointer' onClick={props.handleSearchLocation}>
        {I.search(G.getTheme('icons.iconColorWhite'))}
      </Box>
      <Box ml={10} pt='4px' cursor='pointer' onClick={props.handleSelectLocation}>
        {I.location(G.getTheme('icons.iconColorWhite'))}
      </Box>
    </FormGroupTitleMultiple>
    <FormComponent
      formType={props.formType}
      loadType={props.loadType}
      loadGuid={props.loadGuid}
      eventGuid={props.eventGuid}
      eventType={props.eventType}
      branchGuid={props.branchGuid}
      closeModal={props.closeModal}
      cancelStyles={props.cancelStyles}
      submitHandler={props.submitHandler}
      initialValues={props.initialValues}
      searchedValues={props.searchedValues}
      optionsForSelect={props.optionsForSelect}
      setInitialValues={props.setInitialValues}
      submitAndSaveLocation={props.submitAndSaveLocation}
    />
  </Box>
));
