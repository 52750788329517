import * as R from 'ramda';
import { css } from 'styled-system';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import { pure, compose, withState, lifecycle, withHandlers } from 'react-recompose';
// components
import { FormFooter2 } from '../../../components/form-footer';
// features
import VendorCompensationAccessorials from '../../../features/fleet-profile/components/vendor-compansation-accessorials';
import {
  createCompensationTemplateRequest,
  getCompensationTemplateListRequest,
} from '../../../features/template/report/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
import { DEFAULT_ACCESSORIAL_FORM_RATE_UNIT_GROUP_OPTIONS } from '../../../helpers/options';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex } from '../../../ui';
// forms
import { Fieldset2 } from '../..';
import {
  defaultValues,
  zoneFieldSettings,
  generalFieldSettings,
  getValidationSchemaObject,
  compensationRangeSections,
  compensationDetailsFieldSettings,
} from './settings';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.blue');
const whiteGreyColor = G.getTheme('colors.whiteGrey');

const defaultFieldsWrapperStyles = { px: '0px', pt: 15, width: 992, justifyContent: 'space-between' };

const SectionHeader = withState('expanded', 'setExpanded', R.prop('expanded'))((props: Object) => {
  const {
    title,
    expanded,
    children,
    setExpanded,
  } = props;

  const toggleIconName = G.ifElse(expanded, 'arrowUpSimple', 'arrowDownSimple');

  return (
    <Fragment>
      <Flex
        p='5px'
        my={10}
        fontWeight='bold'
        bg={whiteGreyColor}
        color={darkBlueColor}
        justifyContent='center'
        alignItems='flex-start'
        textTransform='uppercase'
      >
        <Box mr={15} ml='auto'>{title}</Box>
        <Box
          px='6px'
          ml='auto'
          cursor='pointer'
          onClick={() => setExpanded(R.not)}
        >
          {I[toggleIconName](darkBlueColor)}
        </Box>
      </Flex>
      {G.isTrue(expanded) && children}
    </Fragment>
  );
});

const ZoneSection = (props: Object) => (
  <Box
    width={480}
    p='15px 10px'
    borderRadius='8px'
    border='1px solid'
    borderColor={darkBlueColor}
  >
    <Box ml={10} mb={25} fontWeight='bold' color={G.getTheme('colors.light.blue')}>
      {props.title}
    </Box>
    <Fieldset2
      {...props}
      fields={zoneFieldSettings(props.zoneType)}
      setValues={props.handleSetAddressPointValues}
      fieldsWrapperStyles={{ justifyContent: 'space-between' }}
    />
  </Box>
);

const getActionButtons = ({ isEditMode, isSubmitting, setSaveAndAddNew, setSaveAsTemplate }: Object) => {
  const buttonStyles = {
    mr: 20,
    width: 'max-content',
    textTransform: 'uppercase',
  };

  const actionButtons = [
    {
      type: 'submit',
      disabled: isSubmitting,
      buttonStyles: { ...buttonStyles, ml: 'auto' },
      displayText: G.getWindowLocale('titles:save-as-template', 'Save As Template'),
      action: () => {
        setSaveAsTemplate(true);
        setSaveAndAddNew(false);
      },
    },
  ];

  if (G.isTrue(isEditMode)) return actionButtons;

  return R.append(
    {
      buttonStyles,
      type: 'submit',
      disabled: isSubmitting,
      displayText: G.getWindowLocale('titles:save-and-add-new', 'Save and Add New'),
      action: () => {
        setSaveAndAddNew(true);
        setSaveAsTemplate(false);
      },
    },
    actionButtons,
  );
};

export const CompensationForm = (props: Object) => {
  const {
    values,
    fleetType,
    isEditMode,
    handleSubmit,
    isSubmitting,
    initialValues,
    isTemplateForm,
    setSaveAndAddNew,
    optionsForSelect,
    setSaveAsTemplate,
    primaryObjectGuidKey,
    compensationTemplateList,
    handleSetAddressPointValues,
  } = props;

  const compensationTemplateOptions = isTemplateForm
    ? []
    : R.compose(
      G.mapNameGuidObjectPropsToLabelValueObject,
      R.filter(({ templateType }: Object) => R.equals(fleetType, templateType)),
      R.values,
    )(compensationTemplateList);

  const additionFieldComponentProps = ['isTemplateForm'];

  return (
    <form onSubmit={handleSubmit}>
      <Box
        px='6px'
        overflowY='auto'
        overflowX='hidden'
        maxHeight='calc(100vh - 180px)'
        css={css`
          &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
          }
        `}
      >
        {
          <Fieldset2
            {...G.getFormikProps(props)}
            isEditMode={isEditMode}
            fields={generalFieldSettings}
            isTemplateForm={isTemplateForm}
            primaryObjectGuidKey={primaryObjectGuidKey}
            fieldsWrapperStyles={defaultFieldsWrapperStyles}
            compensationTemplateList={compensationTemplateList}
            additionFieldComponentProps={additionFieldComponentProps}
            compensationTemplateOptions={compensationTemplateOptions}
          />
        }
        <Flex mb={15} alignItems='stretch' justifyContent='space-between'>
          <ZoneSection
            {...G.getFormikProps(props)}
            zoneType={GC.FIELD_ORIGIN}
            title={G.getWindowLocale('titles:origin', 'Origin')}
            handleSetAddressPointValues={handleSetAddressPointValues}
          />
          <ZoneSection
            {...G.getFormikProps(props)}
            zoneType={GC.FIELD_DESTINATION}
            handleSetAddressPointValues={handleSetAddressPointValues}
            title={G.getWindowLocale('titles:destination', 'Destination')}
          />
        </Flex>
        <Fieldset2
          {...optionsForSelect}
          {...G.getFormikProps(props)}
          isTemplateForm={isTemplateForm}
          fields={compensationDetailsFieldSettings}
          fieldsWrapperStyles={defaultFieldsWrapperStyles}
          additionFieldComponentProps={additionFieldComponentProps}
          rateUnit={R.pathOr(
            R.of(Array, GC.EMPTY_OPTION_OBJECT),
            [R.path(['values', GC.FIELD_CHARGE_RATE_TYPE], props)],
            DEFAULT_ACCESSORIAL_FORM_RATE_UNIT_GROUP_OPTIONS,
          )}
        />
        {
          compensationRangeSections.map(({ title, fields, fieldSettings }: Object, index: number) => (
            <SectionHeader
              key={index}
              title={G.getWindowLocale(title)}
              expanded={G.isOneNotNilOrNotEmpty(R.values(R.pick(fields, initialValues)))}
            >
              <Fieldset2
                {...G.getFormikProps(props)}
                fields={fieldSettings}
                fieldsWrapperStyles={defaultFieldsWrapperStyles}
              />
            </SectionHeader>
          ))
        }
        {
          G.isAllNotNilOrNotEmpty([G.getGuidFromObject(values), R.prop(GC.FIELD_FLEET_VENDOR_GUID, values)]) &&
          <VendorCompensationAccessorials
            compensationGuid={G.getGuidFromObject(values)}
            accessorialConfigs={G.getPropFromObject('accessorialConfigs', optionsForSelect)}
          />
        }
      </Box>
      <FormFooter2
        submitDisabled={isSubmitting}
        boxStyles={{ mt: 10, px: '5px' }}
        actionButtons={isTemplateForm ? null : getActionButtons(props)}
        submitAction={isTemplateForm ? null : () => {
          setSaveAndAddNew(false);
          setSaveAsTemplate(false);
        }}
      />
    </form>
  );
};

const locationFieldsToPick = [
  GC.FIELD_GEO_FENCING_ZONE_STATES,
  GC.FIELD_GEO_FENCING_ZONE_CITIES,
  GC.FIELD_GEO_FENCING_ZONE_ZIP_CODES,
  GC.FIELD_GEO_FENCING_ZONE_COUNTRIES,
  GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_TO,
  GC.FIELD_GEO_FENCING_ZONE_ZIP_RANGE_FROM,
];

export const getRequestData = (values: Object) => {
  const makeLocationValues = R.compose(
    G.mapObjectEmptyStringFieldsToNull,
    R.pick(locationFieldsToPick),
  );

  const { origin, destination } = values;

  return {
    ...G.mapObjectEmptyStringFieldsToNull(R.omit(['origins', 'destinations'], values)),
    [GC.FIELD_ORIGIN]: makeLocationValues(origin),
    [GC.FIELD_DESTINATION]: makeLocationValues(destination),
  };
};

export const handleSetAddressPointValues = (props: Object) => (entity: Object) => {
  const filteredEntity = R.filter(G.isNotNilAndNotEmpty, entity);

  if (G.isNilOrEmpty(filteredEntity)) return;

  const { values, setValues } = props;

  const { zip, label, city, state, country } = entity;

  let sectionValues = null;

  const pathArray = R.split('.', label);
  const sectionName = R.head(pathArray);
  const searchedFieldName = R.last(pathArray);
  const section = G.getPropFromObject(sectionName, values);

  if (R.equals(searchedFieldName, 'searchCity')) {
    const cities = R.compose(
      R.uniq,
      R.filter(G.isNotNilAndNotEmpty),
      R.append(city),
    )(section.cities);

    const states = R.compose(
      R.uniq,
      R.filter(G.isNotNilAndNotEmpty),
      R.append(state),
    )(section.states);

    const countries = R.compose(
      R.uniq,
      R.filter(G.isNotNilAndNotEmpty),
      R.append(country),
    )(section.countries);

    sectionValues = R.mergeRight(section, { cities, states, countries, searchCity: null });
  }

  if (R.equals(searchedFieldName, 'searchZip')) {
    const zipCodes = R.compose(
      R.uniq,
      R.filter(G.isNotNilAndNotEmpty),
      R.append(zip),
    )(section.zipCodes);
    sectionValues = R.mergeRight(section, { zipCodes, searchZip: null });
  }

  const newValues = R.assoc(sectionName, sectionValues, values);

  setValues(newValues);
};

const enhance = compose(
  connect(null, { createCompensationTemplateRequest, getCompensationTemplateListRequest }),
  withState('saveAndAddNew', 'setSaveAndAddNew', false),
  withState('saveAsTemplate', 'setSaveAsTemplate', false),
  withState('compensationTemplateList', 'setCompensationTemplateList', []),
  withFormik({
    validationSchema: getValidationSchemaObject,
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      R.assoc(GC.FIELD_TEMPLATE_NAME, null, defaultValues),
      initialValues,
    ),
    handleSubmit: (values: Object, { props, resetForm, setSubmitting }: Object) => {
      const {
        fleetType,
        submitAction,
        saveAndAddNew,
        saveAsTemplate,
        createCompensationTemplateRequest,
      } = props;

      const data = getRequestData(values);

      if (saveAsTemplate) {
        return createCompensationTemplateRequest(
          R.assoc(GC.FIELD_TEMPLATE_TYPE, fleetType, G.omitObjectSystemFields(data)),
        );
      }

      const failCallback = () => setSubmitting(false);

      const successCallback = () => {
        setSubmitting(false);

        if (saveAndAddNew) resetForm();
      };

      const additionalOptions = {
        failCallback,
        successCallback,
        shouldCloseModal: G.isFalse(saveAndAddNew),
      };

      submitAction(data, additionalOptions);
    },
  }),
  withHandlers({
    handleSetAddressPointValues,
    handleGetCompensationTemplateListRequest: (props: Object) => () => {
      const { setCompensationTemplateList, getCompensationTemplateListRequest } = props;

      const callback = (data: Object) => {
        const compensationTemplates = R.compose(
          G.indexByGuid,
          R.map((template: Object) => {
            const { origin, destination } = template;

            const makeLocationValues = R.compose(
              G.mapObjectNullFieldsToEmptyStrings,
              R.pick(locationFieldsToPick),
            );

            return {
              ...R.compose(
                G.mapObjectNullFieldsToEmptyStrings,
                R.omit([...GC.GROUPED_FIELDS.SYSTEM_OMIT_ARR_2, GC.FIELD_BRANCH_GUID]),
              )(template),
              [GC.FIELD_ORIGIN]: makeLocationValues(origin),
              [GC.FIELD_DESTINATION]: makeLocationValues(destination),
            };
          }),
        )(data);

        setCompensationTemplateList(compensationTemplates);
      };

      getCompensationTemplateListRequest({ callback });
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.handleGetCompensationTemplateListRequest();
    },
  }),
  pure,
);

export default enhance(CompensationForm);
