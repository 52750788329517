import * as R from 'ramda';
import React, { useEffect } from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, ReactSelect, ReactAsyncSelect } from '../../../ui';
//////////////////////////////////////////////////

export const AsyncReactSelectInput = (props: Object) => {
  const {
    id,
    value,
    width,
    height,
    showIcon,
    isClearable,
    placeholder,
    borderRadius,
    getLoadOptions,
    handleAsyncSearchSelectChange,
  } = props;

  return (
    <Flex>
      <ReactAsyncSelect
        id={id}
        width={width}
        value={value}
        height={height}
        placeholder={placeholder}
        borderRadius={borderRadius}
        getLoadOptions={getLoadOptions}
        isClearable={isClearable || false}
        onChange={handleAsyncSearchSelectChange}
      />
      {showIcon && <Box ml={10}>{I.search()}</Box>}
    </Flex>
  );
};

const ReactSelectInput = (props: Object) => {
  const {
    id,
    value,
    isMulti,
    options,
    onChange,
    hasError,
    disabled,
    components,
    placeholder,
    useMaxHeight,
    setFieldValue,
    shouldValidate,
    setFieldTouched,
    formatOptionLabel,
    closeMenuOnSelect,
    closeMenuOnScroll,
    shouldCustomChange,
    isClearable = true,
    withoutFixedHeight,
    useMenuPortalTarget,
    valueContainerMaxHeight,
    reactSelectAdditionalStyles,
  } = props;

  useEffect(() => {
    if (G.isNotNilAndNotEmpty(shouldValidate)) {
      const validate = G.ifElse(shouldValidate, shouldValidate, false);

      setFieldTouched(id, true, validate);
    }
  }, []);

  const handleBlur = () => {
    const validate = G.ifElse(G.isNotNilAndNotEmpty(shouldValidate), shouldValidate, true);

    setFieldTouched(id, true, validate);
  };

  const handleChange = (value: any) => {
    const isCustomLogic = R.and(G.isTrue(shouldCustomChange), G.isFunction(onChange));
    const inputValue = G.ifElse(R.isNil(value), GC.EMPTY_OPTION_NULLABLE_OBJECT, value);

    if (G.isTrue(isMulti)) {
      const newValue = R.map(
        (item: Object) => G.getPropFromObject('value', item),
        inputValue,
      );

      if (G.isTrue(isCustomLogic)) return onChange(newValue, id);

      setFieldValue(id, newValue);
    } else {
      const newValue = G.getPropFromObject('value', inputValue);

      if (G.isTrue(isCustomLogic)) return onChange(newValue, id);

      setFieldValue(id, newValue);
    }
  };

  const selectProps = {
    id,
    value,
    isMulti,
    options,
    hasError,
    components,
    isClearable,
    placeholder,
    useMaxHeight,
    formatOptionLabel,
    closeMenuOnScroll,
    withoutFixedHeight,
    onBlur: handleBlur,
    useMenuPortalTarget,
    onChange: handleChange,
    valueContainerMaxHeight,
    isDisabled: R.or(disabled, false),
    additionalStyles: reactSelectAdditionalStyles,
    closeMenuOnSelect: G.ifElse(G.isFalse(closeMenuOnSelect), false, true),
  };

  if (R.and(G.isTrue(withoutFixedHeight), G.isNotNilAndNotEmpty(value))) {
    const additionalStyles = {
      input: () => ({
        display: 'none',
      }),
      singleValue: ({ color }: Object) => ({
        color,
        padding: '5px 0',
      }),
    };

    return <ReactSelect {...selectProps} additionalStyles={additionalStyles} />;
  }

  return <ReactSelect {...selectProps} />;
};

export default ReactSelectInput;
