import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { space, width, height, maxWidth } from 'styled-system';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { Flex, Span } from '../../../ui';
//////////////////////////////////////////////////

const Label = styled.label`
  display: block;
  cursor: pointer; 
  user-select:none;
  text-align: left;

  &+.radio {
    margin-top: 12px;
  }

  input {
    display: none;

    &+span {
      position: relative;
      padding-left: 25px;
      display: inline-block;
  
      &:before {
        top: 0px;
        left: 0px;
        width: 16px;
        content: '';
        height: 16px;
        display: block; 
        background: #fff;
        border: 1px solid;
        margin-right: 5px;
        border-radius: 50%;
        position: absolute;
        border-color: ${R.path(['theme', 'colors', '#7D828C'])};
        transition: all .3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
      }

      &:after {
        top: 4px;
        left: 4px;
        opacity: 0;
        width: 10px;
        content: '';
        height: 10px;
        display: block; 
        position: absolute;
        border-radius: 50%;
        transform: scale(0,0); 
        background: ${R.path(['theme', 'colors', 'white'])};
        transition: all .3s cubic-bezier(0.64, 0.57, 0.67, 1.53);
      }
    }

    &: checked+span:before {
      background: ${R.path(['theme', 'colors', 'light', 'blue'])};
    } 

    &: checked+span:after {
      opacity: 1;
      transform: scale(1,1);
    }
  }
`;

const defaultOptions = [
  {
    value: true,
    label: G.getWindowLocale('titles:yes', 'Yes'),
  },
  {
    value: false,
    label: G.getWindowLocale('titles:no', 'No'),
  },
];

const RadioGroup = ({ value, fieldName, setFieldValue, options = defaultOptions }: Object) => (
  <Flex mt={10} width={150} justifyContent='space-between'>
    {options.map(({ label, value: optionValue }: Object, index: number) => (
      <Label key={index}>
        <input
          type='radio'
          id={fieldName}
          name={fieldName}
          value={optionValue}
          checked={R.equals(value, optionValue)}
          onChange={() => setFieldValue(fieldName, optionValue)}
        />
        <Span color={G.ifElse(value, 'light.black', '#999999')}>{label}</Span>
      </Label>
    ))}
  </Flex>
);

export default RadioGroup;
