import * as R from 'ramda';
import React from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { PlacesAutocomplete } from '../../../ui';
//////////////////////////////////////////////////

const getValue = (value: any, concatLocationFields: boolean) => {
  if (R.and(G.isObject(value), G.isTrue(concatLocationFields))) return G.concatLocationFields(value);

  if (G.isObject(value)) return R.pathOr('', [GC.FIELD_ADDRESS_1], value);

  return R.or(value, '');
};

const PlaceAutocomplete = (props: Object) => {
  const {
    id,
    width,
    value,
    values,
    disabled,
    handlers,
    hasError,
    setValues,
    fieldType,
    placeholder,
    omitAddress1,
    setFieldValue,
    locationMapper,
    setFieldTouched,
    addressFieldName,
    shouldHandleEnter,
    useMenuPortalTarget,
    concatLocationFields,
    useFormattedAddress = false,
    customSelectLocationFunction,
  } = props;

  const handleBlur = () => setFieldTouched(id, true);

  const handleChange = (fieldName: string, value: string) => {
    setFieldValue(id, value);

    const changeHandler = G.getPropFromObject('customHandleChange', handlers);

    if (G.isFunction(changeHandler)) {
      changeHandler({ value, fieldProps: props });
    }
  };

  const handleSelect = async (value: string, enter: boolean = false) => {
    const getCatchCallback = () => {
      const handleEnter = R.path(['handleEnter'], handlers);

      if (G.isAllTrue(enter, G.isFunction(handleEnter), shouldHandleEnter)) {
        return () => handleEnter(value, id);
      }

      return null;
    };

    const result = await G.geocodeByPlaceAddress(
      value,
      'forms -> Fieldset2',
      getCatchCallback(),
      { addressFieldName, useFormattedAddress },
    );

    const data = {
      ...result,
      label: id,
      [GC.FIELD_LOAD_BOARD_ORIGIN_CITY]: G.getPropFromObject('city', result), // TODO: check why it is here
    };

    let newValues = G.ifElse(
      G.isTrue(omitAddress1),
      R.omit(GC.FIELD_ADDRESS_1, data),
      data,
    );

    if (G.isNotNil(locationMapper)) newValues = locationMapper(data);

    if (G.isFunction(customSelectLocationFunction)) {
      if (R.equals(fieldType, 'locationObject')) {
        const newValues = R.assoc(id, result, R.or(values, {}));

        setValues(newValues);

        return customSelectLocationFunction({
          newValues,
          fieldName: id,
          location: result,
        });
      }

      return customSelectLocationFunction(newValues, id);
    }

    setValues(R.mergeRight(R.or(values, {}), newValues), id);
  };

  const _value = getValue(value, concatLocationFields);

  return (
    <PlacesAutocomplete
      id={id}
      width={width}
      value={_value}
      hasError={hasError}
      disabled={disabled}
      handleBlur={handleBlur}
      placeholder={placeholder}
      handleSelect={handleSelect}
      setFieldValue={handleChange}
      useMenuPortalTarget={useMenuPortalTarget}
    />
  );
};

export default PlaceAutocomplete;
